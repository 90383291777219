import axios from 'axios'
import url from '../url'
import { setData } from '../resources/authResource'

export default class AuthRepository {
    async forgotPassword(data) {
        const json = setData(data)
        const response = await axios.post(url('forgotPassword'), json)
        return response.data.data
    }

    async forgotPasswordVerify(data) {
        const json = setData(data)
        const response = await axios.post(url('forgetPasswordVerify'), json)
        if (response.status === 200) {
            return response.data.data
        }
    }

    async resetPassword(data) {
        const json = setData(data)
        const response = await axios.post(url('resetPassword'), json)
        if (response.status === 200) {
            return response.data.data
        }
    }
    async checkExistUserName(data) {
        const response = await axios.post(url('checkExistUserName'), data)
        if (response.status === 200) {
            return response.data.data
        }
    }
}
